import { h } from 'preact';
import { Router } from 'preact-router';
import { useEffect, useState } from "preact/hooks";
import Header from './header';
// fonts
import "@fontsource/gloria-hallelujah";
import "@fontsource/inter";
import "@fontsource/roboto";
import "@fontsource/prompt";

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Step1 from '../routes/step1';
import Step2 from '../routes/step2';
import Step3 from '../routes/step3';

const App = () => {
	const [answers, setAnswers] = useState(null);

	return (
		<div id="app">
			<Header />
			<Router>
				<Home default path="/" answers={answers} setAnswers={setAnswers} />
				<Step1 path="/step1/" answers={answers} setAnswers={setAnswers} />
				<Step2 path="/step2/" answers={answers} setAnswers={setAnswers} />
				<Step3 path="/step3/" answers={answers} setAnswers={setAnswers} />
			</Router>
		</div>
	)
}

export default App;

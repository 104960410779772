import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';
// 
import logo from '../../assets/salam.svg';
import user from '../../assets/user.svg';

const Header = () => (
	<header class={style.header}>
		<Link class={style.logoWrapper} href="/">
			<img class={style.salamlogo} src={logo} />
		</Link>
		{/* <nav>
			<Link activeClassName={style.active} href="/login">
				<img class={style.login} src={user} />
			</Link>
		</nav> */}
	</header>
);

export default Header;

import './style';
import App from './components/app';
// scroll
import 'overlayscrollbars/styles/overlayscrollbars.css';
import { OverlayScrollbars } from 'overlayscrollbars';

if (typeof window !== "undefined") {
  const osInstance = OverlayScrollbars(document.querySelector('body'), {
    scrollbars: {
      autoHide: "scroll",
    },
  });
}

export default App;
